import Head from 'next/head'
import { useEffect } from 'react'
import LdJsonList from 'shared-components/service/LdJsonList'
import MetaRecords from 'shared-components/service/MetaRecords'
import { DSHead } from 'shared-definitions/types'
import AlternateLink from './AlternateLink'

interface PageHeadProps {
  head: DSHead
  pageLink: string | null
  rss?: boolean
  ampPageLink: string | null
}

const PageHead: React.VFC<PageHeadProps> = ({ head, pageLink, ampPageLink, rss = true }) => {
  useEffect(() => {
    if (!pageLink) {
      return
    }

    document.head.querySelectorAll('link[rel=canonical]').forEach(l => l.remove())

    const link = document.createElement('link')
    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', pageLink)
    document.head.appendChild(link)
  }, [pageLink])

  return (
    <>
      {head.jsonLd ? <LdJsonList records={head.jsonLd} /> : null}
      <Head>
        {head.metaTags && head.metaTags.length ? <MetaRecords records={head.metaTags} /> : null}
        <title key="title">{head.metaTitle}</title>
        {ampPageLink ? <link key="amphtml-link" rel="amphtml" href={ampPageLink} /> : null}
        {pageLink ? <link key="canonical-link" rel="canonical" href={pageLink} /> : null}
        {pageLink ? <meta key="og-url" property="og:url" content={pageLink} /> : null}
        {pageLink && rss ? <AlternateLink key="rss-alternate" path={pageLink} /> : null}

        {['medium', 'bold', 'regular-italic', 'regular', 'bold-italic'].map(item => (
          <link
            key={`font-${item}`}
            rel="preload"
            href={`/static/fonts/${item}.woff2`}
            as="font"
            type="font/woff2"
            crossOrigin=""
          />
        ))}
      </Head>
    </>
  )
}

export default PageHead
