import styles from './AuthMenu.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { FC } from 'react'
import RWButton from '../buttons/RWButton'
import { AuthContext } from '../contexts/AuthContext'
import { ThemeContext } from '../contexts/ThemeContext'
import ExitRounded from 'shared-svg/ExitRounded.svg'
import EditRounded from 'shared-svg/EditRounded.svg'
import MoonHollow from 'shared-svg/MoonHollow.svg'
import Toggle from './Toggle'
import { CommonDataContext } from '../contexts/CommonDataContext'
import { useRouter } from 'next/router'

interface AuthMenuProps extends BasicStyledComponent {
  onActionComplete?: () => void
}

const AuthMenu: FC<AuthMenuProps> = ({ className, onActionComplete }) => {
  const router = useRouter()
  const {
    common: { profileLink },
  } = CommonDataContext.useContainer()
  const { theme, setTheme } = ThemeContext.useContainer()
  const { logout } = AuthContext.useContainer()

  return (
    <div className={clsx(className, styles.container)}>
      <RWButton
        className={styles.button}
        onClick={() => {
          void router.push(profileLink.href)
          onActionComplete?.()
        }}
        variant="ghost"
        size="small"
      >
        <EditRounded className={styles.icon} />
        {'Edit profile'}
      </RWButton>
      <RWButton
        className={styles.button}
        onClick={() => {
          setTheme(theme === 'light' ? 'dark' : 'light')
          onActionComplete?.()
        }}
        variant="ghost"
        size="small"
      >
        <MoonHollow className={styles.icon} />
        <span className={styles.buttonText}>{'Dark mode'}</span>
        <Toggle
          className={styles.darkModeToggle}
          isOn={theme === 'dark'}
          variant="grey"
          size="small"
        />
      </RWButton>
      <RWButton
        className={styles.button}
        onClick={() => {
          void logout()
          onActionComplete?.()
        }}
        variant="ghost"
        size="small"
      >
        <ExitRounded className={styles.icon} />
        {'Logout'}
      </RWButton>
    </div>
  )
}

export default AuthMenu
