/* eslint-disable promise/prefer-await-to-callbacks */
import { RefObject, useEffect, useRef } from 'react'

/**
{
    characterData: true,
    childList: true,
    subtree: true,
}
 */
export function useMutationObserver<T extends HTMLElement>(
  opts: MutationObserverInit,
  cb: (o: MutationRecord[]) => void
): RefObject<T> {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new MutationObserver(cb)

    observer.observe(ref.current, opts)

    return () => {
      observer.disconnect()
    }
  }, [opts, cb])

  return ref
}
