/* eslint-disable react/self-closing-comp */
// import Head from 'next/head'
import { FC, useEffect } from 'react'
import { useViafoura } from '../hooks/useViafoura'
import './ViafouraStyles.css'

const ViafouraScript: FC = () => {
  useViafoura()

  useEffect(() => {
    // avoid multiply loading
    if (window.vf !== undefined) {
      return
    }

    const script = document.createElement('script')
    script.src = '//cdn.viafoura.net/entry/index.js'
    document.body.appendChild(script)
  }, [])

  return null

  // return (
  //   <Head>
  //     <script async src="//cdn.viafoura.net/entry/index.js" />
  //   </Head>
  // )
}

export default ViafouraScript
