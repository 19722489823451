import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import MobileNavTrigger from 'shared-components/layout/MobileNavTrigger'
import Linked from 'shared-components/service/Linked'
import Cart from 'shared-svg/Cart.svg'
import DesktopNavPlaceholder from 'shared-components/layout/DesktopNavPlaceholder'
import HeaderAuth from 'shared-components/ui/HeaderAuth'
import styles from './Header.module.css'
import Logo from 'shared-svg/LogoAA.svg'
import { ScrollUpContext } from 'shared-components/contexts/ScrollUpContext'
import NotificationsBell from 'shared-components/service/NotificationsBell'
import TopSearch from 'shared-components/layout/TopSearch'
import TopSearchControl from 'shared-components/layout/TopSearchControl'
import { publicConfig } from 'config'

const Header: React.VFC = () => {
  const [activated, setActivated] = useState(false)
  const activatedCb = useCallback(() => setActivated(true), [])
  const { scrolledUp } = ScrollUpContext.useContainer()
  const {
    common: { dealsButton, homeLink, dealsLink },
    showAdminPanel,
  } = CommonDataContext.useContainer()
  const searchEnabled = publicConfig('searchEnabled')

  return (
    <header
      role="button"
      tabIndex={0}
      onClick={activatedCb}
      onKeyDown={activatedCb}
      className={clsx(styles.header, {
        [styles.headerActivated]: activated,
        [styles.preview]: showAdminPanel,
        [styles.scrolledUp]: scrolledUp,
      })}
    >
      {searchEnabled && (
        <TopSearch
          className={styles.topSearch}
          control={TopSearchControl}
          resultsClassName={styles.searchResults}
        />
      )}
      <DesktopNavPlaceholder />
      <MobileNavTrigger searchEnabled={searchEnabled} />
      <Linked {...homeLink} className={styles.logoLink}>
        <Logo className={styles.logoIcon} />
      </Linked>
      <Linked {...dealsLink} className={styles.dealsButton} aria-label="Home">
        {dealsButton.label}
        <Cart className={styles.dealsIcon} />
      </Linked>
      <HeaderAuth className={styles.authBox} />
      <NotificationsBell className={styles.bellButton} />
    </header>
  )
}

export default Header
