import Router from 'next/router'
import { useEffect, useState } from 'react'
import { logNotice } from 'shared-code/log'

export function useIsPreview(): boolean {
  const [isPreview, setIsPreview] = useState(false)
  useEffect(() => {
    setIsPreview(Router.isPreview)
    logNotice('Router.isPreview', { isPreview: Router.isPreview })
  }, [])
  return isPreview
}
