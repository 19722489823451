import { createContainer } from 'shared-components/unstated'
import { DSGetterHandler, DSSearchPage } from 'shared-definitions/types'

interface SearchIncomingProps {
  search: DSGetterHandler<DSSearchPage, [query: string, page: number, perPage: number]>
}

export const SearchContext = createContainer<SearchIncomingProps, SearchIncomingProps>(initial => {
  if (!initial) {
    throw new Error('Undefined context initial value')
  }

  return initial
})
