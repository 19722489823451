import { useEffect, useRef, type DependencyList, type RefObject } from 'react'

export function useOutsideClick<T extends HTMLElement>(
  handler: (e: Event) => void,
  deps: DependencyList
): RefObject<T> {
  const ref = useRef<T>(null)

  useEffect(() => {
    const listener = (event: Event): void => {
      if (!event.target) {
        return
      }
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener, { passive: true })
    document.addEventListener('touchstart', listener, { passive: true })
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return ref
}
