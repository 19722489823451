import { FC } from 'react'
import clsx from 'clsx'
import styles from './Toggle.module.css'
import { BasicStyledComponent } from 'shared-definitions/types'

interface ToggleProps {
  isOn: boolean
  variant: 'grey' | 'primary'
  size: 'small'
}

const Toggle: FC<ToggleProps & BasicStyledComponent> = ({ className, variant, size, isOn }) => (
  <div
    className={clsx(styles.container, className, {
      [styles.variantGrey]: variant === 'grey',
      [styles.variantPrimary]: variant === 'primary',
      [styles.sizeSmall]: size === 'small',
      [styles.on]: isOn,
    })}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={styles.handle}>
      <circle cx="8" cy="8" r="8" fill="var(--circle-outer-color)" />
      <circle cx="8" cy="8" r="7" fill="var(--circle-inner-color)" />
    </svg>
  </div>
)

export default Toggle
