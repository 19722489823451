import definedBreakpoints from 'shared-definitions/breakpoints.json'
import { pxToRem } from 'shared-code/conversion'

export type DesignBreakpoints = keyof typeof definedBreakpoints

// if val: number then it is pixel value, it will be converted to rem
type BreakPointSizeObj = { bp: DesignBreakpoints; val: string | number }

// if number then it is pixel value, it will be converted to rem
export type BreakPointSize = BreakPointSizeObj | string | number

export type BreakpointVisibility = {
  default: boolean
  breakpoints: Partial<Record<DesignBreakpoints, boolean>>
}

export function isCurrentlyVisible(
  breakpoints: Record<DesignBreakpoints, boolean>,
  settings: BreakpointVisibility
): boolean {
  // all keys in ascending order
  const keys: DesignBreakpoints[] = ['sm', 'md', 'lg', 'xl', 'xxl']

  let result = settings.default
  for (const key of keys) {
    if (breakpoints[key]) {
      if (settings.breakpoints[key] !== undefined) {
        result = settings.breakpoints[key] as boolean
      }
    } else {
      break
    }
  }

  return result
}

export function responsiveMap(
  matcher: (bp: DesignBreakpoints) => boolean
): Record<DesignBreakpoints, boolean> {
  return Object.keys(definedBreakpoints).reduce((carry, key) => {
    carry[key] = matcher(key)
    return carry
  }, {} as Record<DesignBreakpoints, boolean>)
}

export function bpToPx(bp: DesignBreakpoints): number {
  return definedBreakpoints[bp]
}

export function responsiveMatcher(bp: DesignBreakpoints): boolean {
  return window.matchMedia(`(min-width: ${pxToRem(definedBreakpoints[bp])}rem)`).matches
}

export function extractSizes(sizes: readonly BreakPointSize[] | BreakPointSize): string {
  const conditions = []
  const s = (Array.isArray(sizes) ? sizes : [sizes]) as BreakPointSize[]

  for (const item of s) {
    if (typeof item === 'number') {
      conditions.push(`${pxToRem(item)}rem`)
    } else if (typeof item === 'string') {
      conditions.push(item)
    } else {
      const { bp, val } = item
      let iVal: string
      if (typeof val === 'string') {
        iVal = val
      } else {
        iVal = `${pxToRem(val)}rem`
      }

      conditions.push(`(min-width: ${pxToRem(definedBreakpoints[bp])}rem) ${iVal}`)
    }
  }
  return conditions.join(', ')
}
