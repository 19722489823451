/* eslint-disable react/button-has-type */
import { FC, PropsWithChildren } from 'react'
import { BasicStyledComponent, LinkedLinkProps } from 'shared-definitions/types'
import Linked from '../service/Linked'
import styles from './RWButton.module.css'
import clsx from 'clsx'

interface RWButtonButton {
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
}

interface RWButtonLink {
  pLink: LinkedLinkProps
  onClick?: () => void
}

type RWButtonVariants = RWButtonButton | RWButtonLink

interface RWButtonProps extends BasicStyledComponent, PropsWithChildren {
  dataAttrs?: Record<`data-${string}`, string>
  variant: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'ghost' | 'outline'
  size: 'small' | 'medium' | 'large'
  disabled?: boolean
}

const RWButton: FC<RWButtonProps & RWButtonVariants> = ({
  disabled,
  children,
  className,
  dataAttrs,
  variant,
  size,
  ...props
}) => {
  const compiledStyle = clsx(className, styles.container, {
    [styles.disabled]: disabled,
    [styles.sizeSmall]: size === 'small',
    [styles.sizeMedium]: size === 'medium',
    [styles.sizeLarge]: size === 'large',
    [styles.outline]: variant === 'outline',
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.tertiary]: variant === 'tertiary',
    [styles.danger]: variant === 'danger',
    [styles.ghost]: variant === 'ghost',
  })

  if ('pLink' in props) {
    return (
      <Linked {...dataAttrs} {...props.pLink} className={compiledStyle} onClick={props.onClick}>
        {children}
      </Linked>
    )
  }

  return (
    <button
      disabled={disabled}
      {...dataAttrs}
      type={props.type ?? 'button'}
      onClick={props.onClick}
      className={compiledStyle}
    >
      {children}
    </button>
  )
}

export default RWButton
