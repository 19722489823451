interface ViafouraCommentCountResponse {
  containerId: string
  commentCount: number
  status: 'hidden' | 'visible'
}

type SortedBy = string

interface TrendingOpts {
  limit: number
  daysPublished: number
  trendWindow: number
}

interface TrendingRecord {
  container_id: string // 'aa_1237158'
  container_title: string // ''
  content_container_uuid: string // '5370d198-c8a7-430b-b46d-bfda158000fb'
  date_published: number // 1731682086911
  is_live: boolean // true
  origin_image_alt: string // ''
  origin_image_url: string // ''
  origin_summary: string // 'Firefox maker Mozilla has recommended that you avoid buying the Samsung Galaxy Watch 3, and other products during Prime Day.2021.'
  origin_title: string // "Mozilla wants you to avoid buying these three 'creepy' gadgets on Prime Day 2021"
  origin_url: string // 'https://staging.androidauthority.com/mozilla-amazon-prime-day-2021-creepy-devices-1237158/'
  recent_comments: number // 0
  section_uuid: string // '00000000-0000-4000-8000-c3655a8b0e2c'
  total_visible_contents: number // 4
}

interface TrendingResponse {
  sorted_by: SortedBy
  trending: TrendingRecord[]
}

export class ViafouraAPI {
  private readonly siteId: string

  constructor(siteId: string) {
    this.siteId = siteId
  }

  // Docs:
  // https://documentation.viafoura.com/reference/gettrendingcontent
  async getTrending(signal: AbortSignal, opts: TrendingOpts): Promise<TrendingResponse> {
    const path = `https://livecomments.viafoura.co/v4/livecomments/${this.siteId}/trending`
    const params = new URLSearchParams({
      limit: opts.limit.toString(),
      content_container_window_days: opts.daysPublished.toString(),
      content_window_hours: opts.trendWindow.toString(),
      sorted_by: 'total_visible_contents',
    })

    const url = path + '?' + params.toString()
    const response = await fetch(url, {
      signal,
      method: 'GET',
      headers: { accept: 'application/json' },
    })

    const data = (await response.json()) as TrendingResponse
    return data
  }

  // Docs:
  // https://documentation.viafoura.com/reference/getcontentcontainerscommentcountandstatusbycontainerids
  async getCommentsCount(
    signal: AbortSignal,
    containerIds: string[]
  ): Promise<ViafouraCommentCountResponse[]> {
    const url = `https://livecomments.viafoura.co/v4/livecomments/${this.siteId}/content-containers/comment-count-and-status/by-container-ids`
    const response = await fetch(url, {
      signal,
      body: JSON.stringify(containerIds),
      method: 'POST',
      headers: { accept: 'application/json', 'content-type': 'application/json' },
    })

    const data = (await response.json()) as ViafouraCommentCountResponse[]
    return data
  }
}
