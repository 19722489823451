import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './TopSearch.module.css'
import { useCallback, useState } from 'react'
import { useGlobalKey } from 'shared-components/hooks/use-global-key'
import { useSearch } from 'shared-components/hooks/use-search'
import InstantSearchResult from 'shared-components/layout/InstantSearchResult'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'
import { useSearchLinkBuilder } from 'shared-components/hooks/use-search-link-builder'
import { CTopSearchControlProps } from 'shared-definitions/types.component'
import { SearchContext } from '../contexts/SearchContext'

interface TopSearchProps extends BasicStyledComponent {
  control: React.ComponentType<CTopSearchControlProps>
  resultsClassName?: string
}

const TopSearch: React.VFC<TopSearchProps> = ({
  className,
  resultsClassName,
  control: Control,
}) => {
  const [focused, setFocused] = useState(false)
  const { search: searchHandler } = SearchContext.useContainer()

  const { ready, xl } = ResponsiveContext.useContainer()
  const { value, setValue, results, pending, clearResults, currentQuery } = useSearch(
    async query => {
      const perPage = ready && xl ? 9 : 12
      const { data } = await searchHandler(query, 1, perPage)
      return data.posts
    }
  )

  const closeCallback = useCallback(() => {
    setFocused(false)
    clearResults()
  }, [clearResults])

  useGlobalKey({ code: 'Escape' }, closeCallback)

  const resultsLinkBuilder = useSearchLinkBuilder()
  const props = {
    focused,
    currentQuery,
    value,
    pending,
    setValue,
    setFocused,
    closeCallback,
  }

  return (
    <>
      <Control {...props} className={className} />
      <InstantSearchResult
        className={clsx(styles.results, resultsClassName, {
          [styles.resultsHidden]: !results || !results.items.length || pending || !focused,
        })}
        resultsLink={resultsLinkBuilder(currentQuery)}
        posts={results}
        onNavigate={closeCallback}
        query={currentQuery}
      />
    </>
  )
}

export default TopSearch
