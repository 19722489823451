import { FC, useState } from 'react'
import styles from './HeaderAuth.module.css'
import { BasicStyledComponent } from 'shared-definitions/types'
import { AuthContext } from '../contexts/AuthContext'
import clsx from 'clsx'
import RWButton from '../buttons/RWButton'
import ArrowBird from 'shared-svg/ArrowBottomBird.svg'
import Person from 'shared-svg/PersonHollow.svg'
import { useOutsideClick } from '../hooks/use-outside-click'
import { useGlobalKey } from '../hooks/use-global-key'
import AuthMenu from './AuthMenu'

const HeaderAuthLoggedIn: FC<BasicStyledComponent> = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const ref = useOutsideClick<HTMLDivElement>(() => {
    setIsMenuOpen(false)
  }, [])
  useGlobalKey({ code: 'Escape' }, () => {
    setIsMenuOpen(false)
  })

  return (
    <div className={clsx(className, styles.loggedIn)} ref={ref}>
      <button
        className={styles.loggedInMenuButton}
        type="button"
        onClick={() => {
          setIsMenuOpen(s => !s)
        }}
      >
        <Person className={styles.loggedInIcon} />
        <ArrowBird
          className={clsx(styles.loggedInArrow, {
            [styles.loggedInArrowOpened]: isMenuOpen,
          })}
        />
      </button>

      <AuthMenu
        onActionComplete={() => {
          setIsMenuOpen(false)
        }}
        className={clsx(styles.loggedInMenu, {
          [styles.loggedInMenuOpened]: isMenuOpen,
        })}
      />
    </div>
  )
}

interface Clickable {
  onClick?: () => void
}

const HeaderAuthLoggedOut: FC<Clickable & BasicStyledComponent> = ({ onClick, className }) => (
  <RWButton
    className={clsx(className, styles.loggedOutButton)}
    onClick={onClick}
    variant="secondary"
    size="medium"
  >
    {'Login'}
  </RWButton>
)

const HeaderAuth: FC<BasicStyledComponent> = ({ className }) => {
  const { showLogIn, authState } = AuthContext.useContainer()

  return authState ? (
    <HeaderAuthLoggedIn className={clsx(className, styles.container)} />
  ) : (
    <HeaderAuthLoggedOut className={clsx(className, styles.container)} onClick={showLogIn} />
  )
}

export default HeaderAuth
