import { createContainer } from 'shared-components/unstated'
import { ViafouraAPI } from 'shared-code/viafoura-api'
import { useRef } from 'react'

interface ViafouraValueProps {
  siteId?: string
}

interface ViafouraContextProps extends ViafouraValueProps {
  api: ViafouraAPI
}

export const ViafouraAPIContext = createContainer<ViafouraContextProps, ViafouraValueProps>(
  initial => {
    if (!initial?.siteId) {
      throw new Error('Undefined context initial value')
    }

    const ref = useRef<ViafouraAPI>(new ViafouraAPI(initial.siteId))
    return {
      api: ref.current,
      ...initial,
    }
  }
)
