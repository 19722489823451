import { useEffect } from 'react'
import { logError, logNotice } from 'shared-code/log'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { PostContext } from 'shared-components/contexts/PostContext'

export type AdInferePathFunc = (pagePath: string, tagLabels: string[]) => string

export const defaultAdInferePath: AdInferePathFunc = (
  _pagePath: string,
  tagLabels: string[]
): string => {
  const paths: { path: string; labels: string[] }[] = [
    {
      path: 'NSFW',
      labels: ['nsfw'],
    },
    {
      path: 'campaigns',
      labels: ['samsung', 'google pixel', 'verizon', 't-mobile', 'att'],
    },
  ]

  for (const path of paths) {
    for (const label of tagLabels) {
      if (path.labels.includes(label)) {
        return path.path
      }
    }
  }
  return 'ROS'
}

interface UseAdPathOpts {
  inferePath: AdInferePathFunc
}

export function useAdPath(opts: UseAdPathOpts): void {
  const { inst } = PlaywireContext.useContainer()
  const { tags } = PostContext.useContainer()

  useEffect(() => {
    logNotice('useAdPath', { inst })
    if (!inst) {
      return
    }
    const { setPath } = inst

    const path = opts.inferePath(
      window.location.pathname,
      tags.map(i => i.label.toLowerCase())
    )
    logNotice(`Setting tyche path to ${path}`)
    try {
      void setPath(path)
    } catch (e) {
      logError('Setting path error: ', e)
    }
  }, [tags, inst])
}
